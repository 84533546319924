import query from "./GetSaleProductsQuery";
import GraphQLClient from "../GraphQL/GraphQLClient";
import SaleProduct from "./SaleProduct";
import Price from "../Price/Price";

export const getSaleProducts = async (tag, promoCode) => {
  tag = tag || null;
  promoCode = promoCode || null;

  const data = await GraphQLClient.request(query, { tag, promoCode });

  return data.sale.productVariants.reduce((result, productVariant) => {
    result.push(
      new SaleProduct(
        productVariant.id,
        productVariant.name,
        productVariant.thumbnailUrl,
        productVariant.saleProduct.productCardTitle,
        productVariant.saleProduct.isBestseller,
        productVariant.saleProduct.shortDescription,
        productVariant.saleProduct.longDescription,
        productVariant.saleProduct.lengthLabel,
        new Price(
          productVariant.saleProduct.price.regular.amount,
          productVariant.saleProduct.price.regular.currency
        ),
        productVariant.saleProduct.price.promo
          ? new Price(
              productVariant.saleProduct.price.promo.amount,
              productVariant.saleProduct.price.promo.currency
            )
          : null
      )
    );
    return result;
  }, []);
};
