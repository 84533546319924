import { PromoCodeLocalStorageIdentifier } from "./PromoCodeIdentifiers";

export const getPromoCodeFromLocalStorage = () =>
  global.localStorage
    ? global.localStorage.getItem(PromoCodeLocalStorageIdentifier)
    : "";

export const setPromoCodeToLocalStorage = promoCode => {
  if (global.localStorage) {
    global.localStorage.setItem(PromoCodeLocalStorageIdentifier, promoCode);
  }
};
