const query = `
    query HomePageQuery($promoCode: String, $tag: String) {
        sale {
          productVariants(promoCode: $promoCode, tag: $tag) {
            ...saleProductVariantDetails
          }
        }
      }

    fragment saleProductVariantDetails on SaleProductVariant {
      id
      name
      thumbnailUrl
      saleProduct {
        price {
          regular {
            amount
            currency
          }
          promo {
            amount
            currency
          }
        }
        productCardTitle
        isBestseller
        shortDescription
        longDescription
        lengthLabel
      }
    }
  `;

export default query;
