import { GraphQLClient } from "graphql-request";
import 'url-search-params-polyfill';
import getCookie from '../helpers/getCookie';
import setCookie from '../helpers/setCookie';

const getAuthToken = () => {

    if (global && global.location) {
        const urlParams = new URLSearchParams(global.location.search);

        if (urlParams.has('a')) {
            setCookie("Authentication", urlParams.get('a'), 365);
        }

        return getCookie('Authentication');
    }

    return null;
};


const client = new GraphQLClient(process.env.API_URL, {
    mode: "cors",
    headers: {
        "X-Authentication": getAuthToken()
    }
});

export default client;
