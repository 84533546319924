// Copied from https://github.com/DietLabs/front-hdba/blob/master/src/ExpiringLocalStorage.js

class ExpiringLocalStorage {
    constructor(namespace, ttl_ms) {
        this.namespace = namespace;
        this.ttl_ms = ttl_ms;
    }

    static supportsLocalStorage() {
        return global && global.localStorage;
    }

    createKey(name) {
        return `${this.namespace}:${name}`;
    }

    createExpire() {
        if (this.ttl_ms == null) {
            return null;
        }

        return this.ttl_ms + Date.now();
    }

    setValue(name, value) {
        const expire = this.createExpire();
        if (expire == null) {
            throw new Error(
                'Cannot create expire time for ExpiringLocalStorage::setValue(). No TTL set?'
            );
        }

        this.setItem(name, value, expire);
    }

    setItem(name, value, expire) {
        if (!this.constructor.supportsLocalStorage) {
            return;
        }

        const key = this.createKey(name);

        global.localStorage.setItem(key, JSON.stringify({ value, expire }));
    }

    removeItem(name) {
        if (!this.constructor.supportsLocalStorage) {
            return;
        }

        const key = this.createKey(name);

        global.localStorage.removeItem(key);
    }

    getValue(name) {
        const item = this.getItem(name);

        return item == null ? null : item.value;
    }

    getItem(name) {
        if (!this.constructor.supportsLocalStorage) {
            return null;
        }

        const key = this.createKey(name);
        const valueWithExpire = global.localStorage.getItem(key);

        if (valueWithExpire === null) {
            return null;
        }

        try {
            const item = JSON.parse(valueWithExpire);

            if (item.expire === undefined) {
                throw new Error('Expire time missing.');
            }

            if (item.expire < Date.now()) {
                throw new Error('Expired');
            }

            return item;
        } catch (error) {
            this.removeItem(name);
            return null;
        }
    }

    clear() {
        if (!this.constructor.supportsLocalStorage) {
            return;
        }

        Object.keys(global.localStorage)
            .filter(key => key.startsWith(`${this.namespace}:`))
            .forEach(key => global.localStorage.removeItem(key));
    }
}

export default ExpiringLocalStorage;
