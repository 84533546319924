import { PromoCodeQSIdentifier } from "./PromoCodeIdentifiers";

export const addToCartLink = (productId, promoCode, source) => {
  let link = `${process.env.REACT_APP_URL}/cart/add/${productId}`;

  if (source) {
    link += `/${source}`;
  }

  if (promoCode) {
    link += `?${PromoCodeQSIdentifier}=${promoCode}`;
  }

  return link;
};
