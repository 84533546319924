import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getSaleProducts } from './GetSaleProducts';
import { getPromoCodeFromQueryString } from './GetPromoCodeFromQueryString';
import {
    getPromoCodeFromLocalStorage,
    setPromoCodeToLocalStorage,
} from './PromoCodeFromLocalStorage';
import ExpiringLocalStorage from '../ExpiringLocalStorage';
import 'url-search-params-polyfill';

export const SaleProductContext = React.createContext();

const SOURCE_HOMEPAGE = 'homepage';
// Copied from https://github.com/DietLabs/front-hdba/blob/master/src/view/SaleSources/SaleSourcesNames.js
const SOURCE_FIRST = 'first';
const SOURCE_LAST = 'last';
// Copied from https://github.com/DietLabs/front-hdba/blob/master/src/view/SaleSources/SaleSourcesNamespace.js
const SOURCE_NAMESPACE = 'saleSources';
// Copied from https://github.com/DietLabs/front-hdba/blob/master/src/view/SaleSources/Set/SaleSourcesSetAction.js
const SOURCE_TTS_MS = 24 * 60 * 60 * 1000; // 24h

const SaleProductProvider = (props) => {
    const [saleProducts, setSaleProducts] = useState([]);
    const [promoCode, setPromoCode] = useState(null);
    const [source, setSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
      const sourceLocalStorage = new ExpiringLocalStorage(
          SOURCE_NAMESPACE,
          SOURCE_TTS_MS
      )
      const storeSource = source => {
          if (!source && source !== SOURCE_HOMEPAGE) {
              return
          }

          if (!sourceLocalStorage.getValue(SOURCE_FIRST)) {
              sourceLocalStorage.setValue(SOURCE_FIRST, source)
          }
          sourceLocalStorage.setValue(SOURCE_LAST, source)
      }

      const getPromoCode = () => {
          let promoCode

          if (getPromoCodeFromQueryString()) {
              promoCode = getPromoCodeFromQueryString()
              setPromoCodeToLocalStorage(promoCode)
          } else if (getPromoCodeFromLocalStorage()) {
              promoCode = getPromoCodeFromLocalStorage()
          } else {
              promoCode = props.promoCode
          }

          return promoCode
      }

      const getSource = () => {
          return getSourceFromQuery() || props.source
      }

      async function fetchSaleProducts() {
          setIsLoading(true)
          const promoCode = getPromoCode()
          const source = getSource()
          storeSource(source)
          const saleProducts = await getSaleProducts(props.tag, promoCode)
          setSaleProducts(saleProducts)
          setIsLoading(false)
          setSource(source)
          setPromoCode(promoCode)
      }

      fetchSaleProducts()
  }, [props.promoCode, props.source, props.tag])


    const getSourceFromQuery = () => {
        const urlParams = new URLSearchParams(global.location.search);
        return urlParams.get('source');
    };

    return (
        <SaleProductContext.Provider
            value={{ saleProducts, promoCode, source, isLoading }}
        >
            {props.children}
        </SaleProductContext.Provider>
    );
};

SaleProductProvider.defaultProps = {
    promoCode: null,
};

SaleProductProvider.propTypes = {
    children: PropTypes.node.isRequired,
    tag: PropTypes.string.isRequired,
    promoCode: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default SaleProductProvider;
export const SaleProductConsumer = SaleProductContext.Consumer;
