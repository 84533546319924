function setCookie(cname, cvalue, exdays) {
    if (typeof document !== `undefined`) {
        let day = new Date();
        day.setTime(day.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ day.toUTCString();

        let domain = document.location.host.replace('app.', '');
        domain = domain.replace('dev.', '');
        domain = domain.replace('stage.', '');

        if (!domain.startsWith('.')) {
            domain = `.${domain}`;
        }

        document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${domain}`;

    }
}

export default setCookie;
