export default class Price {
  constructor(amount, currency) {
    this.amount = Number(amount) ? Math.round(Number(amount) * 100) / 100 : 0;
    this.currency = currency;
    if(this.currency === 'PLN') this.currency = 'zł'
  }

  toString() {
    return `${this.amount}  ${this.currency}`;
  }
}
